$('.js-report-table').on('change', '.js-property-block-for-transfer-in select', async function (e) {
    let target = e.target;
    let propertyId = $(target).val();

    await updateCattleTypesBySelectedProperty(target, propertyId, null, false);

});

export async function updateCattleTypesBySelectedProperty(target, propertyId, action = null, isUpdate = false)
{
    let multipleAttr = $(target).closest('tr').find('.js-action-select-cattle-type').attr('multiple');

    let transactionId = isUpdate ? $(target).closest('tr').find('input[name="transaction_id"]').val() : '';

    if ( $('.js-cattle-types-buffer option').length === 0 ) {
        $('.js-cattle-types-buffer').append( $(target).closest('tr').find('.js-action-select-cattle-type').prop('innerHTML') )

    }
    console.log(propertyId)

    await axios.get(`/property/getPropertyDataAjax/${propertyId}?isreport=yes&transactionId=${transactionId}`)
        .then(propertyFromDataResponse => {
            let optionsTags = '';

            if ( action !== 'Brand' && action !== 'Bought' ) {
                optionsTags = '<option value="all">All</option>\n';
            }

            for (let cattleType in propertyFromDataResponse.data.cattleTypes) {
                optionsTags += `<option value="${propertyFromDataResponse.data.cattleTypes[cattleType][0].id}">${propertyFromDataResponse.data.cattleTypes[cattleType][0].name}</option>\n`
            }

            $(target).closest('tr').find('.js-action-select-cattle-type').empty();
            $(target).closest('tr').find('.js-action-select-cattle-type').append(optionsTags)
        })



}
