
$('.color-picker').spectrum({
    type: 'component',
    showAlpha: false,
    cancelText: 'Reset',
    chooseText: '',
    containerClassName: 'cpicker-container'
});

$('[data-toggle="popover"]').popover({
    trigger: 'hover focus',
    html: true,
  });

$('[data-bs-toggle="popover-buttons"]').popover({
    html: true,
    sanitize: false,
    trigger: 'click',
    placement: 'bottom',
})

$('body').on('click', function (e) {
    $('[data-bs-toggle="popover-buttons"]').each(function () {
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
            $(this).popover('hide');
        }
    });
});



$(function() {
    $('button.sp-choose').remove();
    datepickerInit();
})

export function datepickerInit()
{
    $('.datepicker--format_short').datepicker({
        assumeNearbyYear: '20',
        format: 'dd-mm-yy',
        autoclose: true,
        todayBtn: true,
        todayHighlight: true,
        orientation: 'bottom'
    }).on('changeDate', function(event) {
        if (!$(this).next().is('input[type="hidden"]')) {
            return;
        }

        const date = event.date;
        const month = date.getMonth() + 1;
        const formattedMonth = month >= 10 ? month : `0${month}`;
        const formattedDay = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;

        let formattedDate = `${date.getFullYear()}-${formattedMonth}-${formattedDay}`;
        $(this).next().val(formattedDate);
    }).on('clearDate', function(event) {
        if (!$(this).next().is('input[type="hidden"]')) {
            return;
        }

        $(this).next().val('');
    });
}
