import {default as axios} from "axios";
import {setInvalid} from "../cattle-type/actions-cattle-type";

export async function smaValidation(chosenAction, elSma, transactionId) {
    if (chosenAction === 'Bought' || chosenAction === 'Sold'
        || chosenAction === 'Transfer In' || chosenAction === 'Transfer Out'
    ) {
        if (elSma.length !== 0) {
            if (elSma.val() !== undefined && elSma.val() === '') {
                setInvalid(elSma, 'The SMA field is required');
                modalActionsValidated = false;
            } else if (elSma.val().length > 12) {
                setInvalid(elSma, 'The SMA must not be greater than 12 characters');
                modalActionsValidated = false;
            }
        }

        let sma = elSma.val();
        let result = await axios.get(`/unique-sma?sma=${sma}&transaction_id=${transactionId}`)
            .then(response => {
                if (response.data.status !== 'success') {
                    setInvalid(elSma, 'This SMA is already taken');
                    modalActionsValidated = false;
                    console.log('sma dupl')
                }
            });

        return result

    }
}
