export function initSelectpickerCustomization(container = 'body') {
    $(`${container} .selectpicker[data-selected-text-format="static"][multiple]`).each((index, select) => {
        const optionList = $(select).find('option');
        let selectedOptionList = optionList.filter('[selected]');

        const selectedOptionsBlock = document.createElement('div');
        $(selectedOptionsBlock).addClass('d-flex flex-wrap gap-2');

        renderSelectedOptions(select, selectedOptionList, selectedOptionsBlock);

        if ($(select).parent().hasClass('bootstrap-select')) {
            $(select).parent().parent().append(selectedOptionsBlock);
        } else $(select).parent().append(selectedOptionsBlock);

        $(container).on('changed.bs.select', select, function (e, clickedIndex, isSelected, previousValue) {
            selectedOptionList = $(select).find('option:selected');

            $(selectedOptionsBlock).html('');

            renderSelectedOptions(select, selectedOptionList, selectedOptionsBlock);

          });
    });

    $(`${container} .selectpicker[data-selected-text-format="static"]`).not('[multiple]').each((index, select) => {
        const optionList = $(select).find('option');
        let selectedOption = optionList.filter('[selected]');

        const selectedOptionsBlock = document.createElement('div');

        renderSingleSelectedOption(select, selectedOption, selectedOptionsBlock);
        if ($(select).parent().hasClass('bootstrap-select')) {
            $(select).parent().parent().append(selectedOptionsBlock);
        } else $(select).parent().append(selectedOptionsBlock);

        $(container).on('changed.bs.select', select, function (e, clickedIndex, isSelected, previousValue) {
            selectedOption = $(select).find('option:selected');

            $(selectedOptionsBlock).html('');

            renderSingleSelectedOption(select, selectedOption, selectedOptionsBlock);

          });
    });

    function renderSelectedOptions(select, selectedOptionList, appendTo) {
        selectedOptionList.each((index, option) => {
            const optionHtml = document.createElement('div');
            const deselectOptionButton = document.createElement('button');

            $(optionHtml)
                .addClass('border border-1 py-1 px-2 rounded-1')
                .attr('data-value', $(option).attr('value'))
                .html($(option).text().trim());

            $(deselectOptionButton)
                .addClass('btn btn-sm btn-link text-body text-decoration-none p-0 lh-1')
                .attr('type', 'button')
                .html('<i class="bi bi-x fs-5"></i>')
                .on('click', function(e) {
                    const selectValue = $(select).val();
                    const deselectValue = $(this).parent().attr('data-value');

                    $(select).selectpicker('val', selectValue.filter(value => value !== deselectValue));

                    $(select).trigger('change')
                })
                .appendTo(optionHtml);



            $(optionHtml).appendTo(appendTo);
        });
    }

    function renderSingleSelectedOption(select, selectedOption, appendTo) {
        const optionHtml = document.createElement('span');
        $(optionHtml)
            .addClass('text-body fs-6')
            .html(selectedOption.text().trim());

        $(optionHtml).appendTo(appendTo);
    }
}
