import {addFutureYearToSelect} from "../cattle-type/table-actions-cattle-type";
import {changeAction} from "./change-action-handle";
import {onChangeCattleType} from "./change-cattle-type-handle";
import {updateAgesSelector} from "./change-target-cattle-type-handle";
import {updateCattleTypesBySelectedProperty} from "./change-transfer-in-property-handle";

export async function fillTransactionData(data, transactionId, isCopy)
{
    let action = data['action']

    const actionBlock = $('.js-report-table .action-in-table')

    if ( !isCopy ) {
        $('.js-report-table .action-in-table')
            .append(`<input type="hidden" name="transaction_id" value="${transactionId}" form="actionsFormInTable"/>`)
    }



    let actionsSelect = $('.js-report-table .js-action-select-in-table')
    $(actionsSelect).val(action);

    await fillPropertyData(actionBlock, data, transactionId)

    if ( isCopy ) {
        await changeAction(actionsSelect, false, data, isCopy)
    } else {
        await changeAction(actionsSelect, true, data, isCopy)
    }


    actionBlock?.find('input[name="custom_created_at_datepicker"]')
        ?.datepicker('update', data?.transaction_date)

    actionBlock?.find('input#actionDate')
        ?.val(data?.custom_created_at?.substring(0, 10))

    actionBlock?.find('input[name="ms_number"]').val(data?.ms_number)


    switch (action) {
        case 'Transfer Out':
        case 'Transfer In':
        case 'Bought':
        case 'Sold':

            actionBlock?.find('input[name="nvd[]"]').val(data['nvd'] );
            actionBlock?.find('input[name="decks[]"]').val(data['decks'] );
            actionBlock?.find('select[name="transport[]"]').val(data['transport']).trigger('change');

            if ( !isCopy ) {
                actionBlock?.find('input[name="sma[]"]').val(data?.sma)
            }

            break;
    }


    if ( data['mob'] ) {

        if ( data['action'] === 'Transfer In' ) {
            let propertySelectFrom = $(actionBlock).find('#propertyFromMove')
            $(propertySelectFrom).val(data['property_to']);
        }

        actionBlock?.find('input[name="mob[]"]').val(data?.mob)
        await $('.js-edit-mob-in-table:first i').trigger('click');

        setTimeout(() => {
            $('.modal#mob .save-btn').trigger('click');
        }, 500)


    } else {

        if ( action === 'Brand' || action === 'Bought' ) {
            let cattleTypeId = data['cattle_type_from']
            let cattleTypeSelect = actionBlock?.find('.js-action-select-cattle-type:last')

            $(cattleTypeSelect).val(cattleTypeId);
            await onChangeCattleType(cattleTypeSelect)

        } else {
            actionBlock?.find(`.js-action-select-cattle-type:first option[value="${data['cattle_type_from']}"]`).attr('data-prefilled-type', '1')
            actionBlock?.find(`.js-action-select-cattle-type:first option[value="${data['cattle_type_from']}"]`).removeClass('d-none')

            let cattleTypeId = data['cattle_type_from']
            let cattleTypeSelect = actionBlock?.find('.js-action-select-cattle-type:last')

            $(cattleTypeSelect).val(cattleTypeId);
            await onChangeCattleType(cattleTypeSelect)

        }

        $('.js-report-table .js-select-year-of-birth:first').val(data['current_year_of_birth']).trigger('change');
        $('.js-report-table #quantity').val(data['quantity'])

    }

    switch (data['action']) {
        case 'Moved':
            $('.js-report-table #paddockToMove').val(data['paddock_to']).trigger('change');
            break;
        case 'Transfer In':
            let propertySelectFrom = $(actionBlock).find('#propertyFromMove')
            $(propertySelectFrom).val(data['property_to']);
            console.log(data['property_to'])

            await updateCattleTypesBySelectedProperty(propertySelectFrom, data['property_to'], null, true)

            $('.js-report-table .js-action-select-cattle-type:not(.d-none)').val(data['cattle_type_from']).trigger('change');
            break;
        case 'Transfer Out':
        case 'Transfer-To-User':
            $('.js-report-table .js-property-block-for-transfer-out #propertyToMove').val(data['property_to']).trigger('change');
            break;
        case 'Reclass':
        case 'Weaned':
            let cattleTypeToSelect = actionBlock?.find('select#cattleToReclass')

            $(cattleTypeToSelect).val(data['cattle_type_to'])
            await updateAgesSelector(cattleTypeToSelect)


            $('.js-report-table select[name="updated_year_of_birth[]"]:first').val(data['updated_year_of_birth']).trigger('change');
            break;
        case 'Process Weaners':
            let cattleTypeToSelect2 = actionBlock?.find('select#cattleToReclass')
            $(cattleTypeToSelect2).val(data['cattle_type_to'])
            await updateAgesSelector(cattleTypeToSelect2)


            $('.js-report-table select[name="updated_year_of_birth[]"]:first').val(data['updated_year_of_birth']).trigger('change');
            $('.js-report-table #paddockToMove').val(data['paddock_to']).trigger('change');
            break;
        case 'Change Age':

            if ( data['mob'] ) {
                let MOBcattleTypeData = await axios.get(`/get-mob-cattle-type-ages-ajax/${data['mob']}`);
                let cattleTypeMinYearOfBirth = MOBcattleTypeData.data.data.min_year_of_birth;
                let currentYear = new Date().getFullYear();

                let selectOptions = '';
                selectOptions += `<option value="mixed">mixed</option>`;
                selectOptions += addFutureYearToSelect();
                for (let i = currentYear; i >= cattleTypeMinYearOfBirth; i--) {
                    selectOptions += `<option value="${i}">${i}</option>`;
                }

                $('.js-report-table .js-destination select[name="updated_year_of_birth[]"]').empty().append(selectOptions).val(data['updated_year_of_birth']).trigger('change');
            } else {
                $('.js-report-table .js-destination select[name="updated_year_of_birth[]"]').val(data['updated_year_of_birth']).trigger('change');
            }


            break;
        case 'Sold':
            $('.js-report-table .js-property-block-for-sale #propertyToMove').val(data['property_to']).trigger('change');
            break;
    }


    if ( data['female_status'] !== null && $(`.js-report-table select[name="female_status[]"] option[value="${data['female_status']}"]`).length === 0 ) {
        $('.js-report-table select[name="female_status[]"]').append(`<option data-type="custom" value="${data['female_status']}">${data['female_status']}</option>`)
    }
    $('.js-report-table select[name="female_status[]"]').val(data['female_status'] ?? '0');

    $('.js-report-table select[name="agent[]"]').val(data['agent']);
    $('.js-report-table input[name="sma_note[]"]').val(data['sma_note']);
    $('.js-report-table input[name="note[]"]').val(data['note']);
    $('.js-report-table input[name="head_price[]"]').val(data['head_price']);
    $('.js-report-table input[name="purchased_from[]"]').val(data['purchased_from']).trigger('input');
    $('.js-report-table input[name="purchased_to[]"]').val(data['purchased_to']);

    if ( data['treatment'] !== null ) {
        $('.js-report-table select[name="treatment[0][]"] option').each(function (i) {
            if ( $(this).val().toString() !== '' && data['treatment'].includes($(this).val().toString()) ) {
                $(this).attr('selected', 'selected')
            } else {
                $(this).removeAttr('selected')
            }
        })
    }


}

async function fillPropertyData(actionBlock, data, transactionId)
{
    const propertyId = data['property_from'],
        paddockId = data['paddock_from']

    $('input[name="property_id"]').val(propertyId)
    $('input[name="current_paddock_id"]').val(paddockId)

    if ( data['action'] !== 'Transfer-In' && window.location.pathname === '/reports' ) {
        await axios.get(`/property/getPropertyDataAjax/${propertyId}?isreport=yes&transactionId=${transactionId}`)
            .then(propertyFromDataResponse => {
                let optionsTags = '';

                optionsTags = '<option value="all">All</option>\n';

                for (let cattleType in propertyFromDataResponse.data.cattleTypes) {
                    optionsTags += `<option value="${propertyFromDataResponse.data.cattleTypes[cattleType][0].id}">${propertyFromDataResponse.data.cattleTypes[cattleType][0].name}</option>\n`
                }

                $(actionBlock).find('.js-action-select-cattle-type').empty().append(optionsTags)
            })
    }


    await axios.get(`/get-property-treatments?property_id=${propertyId}`)
        .then(treatmentsData => {
            let optionsTags = '';

            for (let treatment in treatmentsData.data.treatments) {
                optionsTags += `<option value="${treatment}">${treatmentsData.data.treatments[treatment]}</option>\n`
            }

            $(actionBlock).find('select[name="treatment[0][]"]').empty().append(optionsTags).val('0')
        })

    await axios.get(`/get-property-bought-notes?property_id=${propertyId}`)
        .then(notesData => {
            let optionsTags = '';

            for (let note in notesData.data.notes) {
                optionsTags += `<option value="${note}">${notesData.data.notes[note]}</option>\n`
            }

            $(actionBlock).find('select[name="purchased_from[]"]').empty().append(optionsTags)
        })

    await axios.get(`/get-property-transports?property_id=${propertyId}`)
        .then(transportsData => {
            let optionsTags = '';

            for (let transport in transportsData.data.transports) {
                optionsTags += `<option value="${transport}">${transportsData.data.transports[transport]}</option>\n`
            }

            $(actionBlock).find('select[name="transport[]"]').empty().append(optionsTags).val('0')
            $('.modal#sma').find('select[name="transport[]"]').empty().append(optionsTags).val('0')
        })

    await axios.get(`/get-property-agents?property_id=${propertyId}`)
        .then(agentsData => {
            let optionsTags = '';

            for (let agent in agentsData.data.agents) {
                optionsTags += `<option value="${agent}">${agentsData.data.agents[agent]}</option>\n`
            }

            $(actionBlock).find('select[name="agent[]"]').empty().append(optionsTags).val('0')
            $('.modal#sma').find('select[name="agent[]"]').empty().append(optionsTags).val('0')
        })



}

