import {smaValidation} from "../fields-validation/sma";

const { default: axios } = require("axios");
import { hide } from '@popperjs/core';
import {addFutureYearToSelect} from './table-actions-cattle-type';
import {alertOnNegateExtraAnimals, extraAnimalsAlert} from "../actions-alerts";


/**
 * @param  {string} cattleTypeGender
 */
function femaleStatusBlockHandlerInTable(cattleTypeGender, row) {
    cattleTypeGender === 'Female'
        ? $(row).find('.js-select-cattle-female-status').removeAttr('disabled')
        : $(row).find('.js-select-cattle-female-status').attr('disabled', '');
}


/**
 * @param {HTMLDivElement} el action block
 *
 * @return {void}
 */
export async function updateAgesSelectsInAModal(el) {

    let cattleTypeId = $(el).find('td[data-cattle-type-from] select:not([disabled])').val();
    const currentAge = $(el).find('select[name="year_of_birth[]"]').val();

    let propertyId = $('input[name="property_id"]:last').val();
    let paddockId = $('input[name="current_paddock_id"]:last').val();
    const prefilledYearOfBirth = $(el).find('td[data-current-year-of-birth] select:first').val();
    const action = $(el).find('.js-action-select-in-table').val();

    const transactionId = $('input[name="transaction_id"]').val()


    el.find('.js-select-year-of-birth[name="year_of_birth[]"]').html('');
    if (el.closest('.js-report-table').length > 0) {
        let chosenAction = el.closest('.action-in-table').find('.js-action-select-in-table').val();

        if(chosenAction === 'Transfer In') {
            propertyId = el.closest('.action-in-table').find('td[data-property-from-id] #propertyFromMove').val();
        }

        if (
            chosenAction !== 'Brand' && chosenAction !== 'Bought'
        ) {

            el.find('.js-select-year-of-birth[name="year_of_birth[]"]').append(`<option value="all">All</option>`);
        }
    }

    $('input[name="current_cattle_type_id[]"]').val(cattleTypeId);


    if ( cattleTypeId ) {
        if ( cattleTypeId !== 'all' && !cattleTypeId.includes('all') ) {
            if ( cattleTypeId != '' ) {
                await axios.get('/property-cattle-type?cattle_type_id=' + cattleTypeId + '&property_id='
                    + propertyId + '&paddock_id=' + paddockId + '&transaction_id=' + transactionId + '&action=' + action)
                    .then((response) => {
                        let cattleTypeMinYearOfBirth = response.data.data.min_year_of_birth;
                        let cattleTypeAgeBorder = response.data.data.age_border_year  + '+';
                        let cattleTypeAgeBorderName = response.data.data.age_border_name ?? response.data.data.age_border_year + '+';
                        let cattleTypeGender = response.data.data.gender;
                        let cattleTypeHideAgeBorder = response.data.data.hide_border_ages
                        let cattleTypeHidden = response.data.data.hide_years_of_birth

                        let currentYear = new Date().getFullYear();

                        let selectOptions = '';
                        let selectOptionsTo = ''
                        if (action === 'Brand' || action === 'Bought') {

                            if ( cattleTypeHidden ) {
                                selectOptions += `<option value="hidden">hidden</option>`;
                            } else {
                                selectOptions += `<option value="mixed">mixed</option>`;
                                selectOptions += addFutureYearToSelect();
                                for (let i = currentYear; i >= cattleTypeMinYearOfBirth; i--) {
                                    selectOptions += `<option value="${i}">${i}</option>`;
                                }
                            }

                        } else {
                            let cattleTypeHidden = response.data.data.hide_years_of_birth
                            let ages = response.data.data.ages;

                            if ( cattleTypeAgeBorder != 'null+' ) {
                                selectOptions += `<option value="${cattleTypeAgeBorder}">${cattleTypeAgeBorderName}</option>`;
                            }

                            if ( cattleTypeHidden && ages.includes(null) ) {
                                selectOptions += `<option value="hidden">hidden</option>`;
                            } else if ( ages.includes(null) ) {
                                selectOptions += `<option value="mixed">mixed</option>`;
                            }

                            if ( !cattleTypeHidden ) {
                                ages.forEach(function(i) {
                                    if ( i == null
                                        || (cattleTypeHideAgeBorder == '1' && cattleTypeAgeBorder != 'null+' && i <= cattleTypeAgeBorder.split('+')[0])  ) {
                                        return;
                                    }
                                    selectOptions += `<option value="${i}">${i}</option>`;
                                })

                                selectOptionsTo += `<option value="mixed">mixed</option>`;
                                selectOptionsTo += addFutureYearToSelect();
                                for (let i = currentYear; i >= cattleTypeMinYearOfBirth; i--) {
                                    selectOptionsTo += `<option value="${i}">${i}</option>`;
                                }
                            } else {
                                selectOptionsTo += `<option value="hidden">hidden</option>`;
                            }





                        }




                        el.find('.js-select-year-of-birth[name="year_of_birth[]"] option:not([value="all"])').remove();
                        el.find('.js-select-year-of-birth[name="year_of_birth[]"]').append(selectOptions);

                        if ( el.find(`td[data-current-year-of-birth] select[name="year_of_birth[]"] option[value="${prefilledYearOfBirth}"]`).length !== 0 ) {
                            el.find('td[data-current-year-of-birth] select[name="year_of_birth[]"]').val(prefilledYearOfBirth).trigger('change');
                        } else {
                            el.find('td[data-current-year-of-birth] select[name="year_of_birth[]"] option:eq(1)').attr('selected', 'selected').trigger('change');
                        }


                        if ( (new URL(document.location)).searchParams.get('resend') !== null ) {
                            el.find('td[data-current-year-of-birth] select').val((new URL(document.location)).searchParams.get('age'));
                        }


                        if ( action === 'Change Age' ) {
                            const currentUpdatedAge = $(el).find('select[name="updated_year_of_birth[]"]:last').val();

                            if ( $(el).hasClass('pre-filled') || el.find('.js-select-year-of-birth:last').filter('[disabled]').length === 0 ) {
                                if (!el.hasClass('js-reclass-action')) {

                                    el.find('.js-select-year-of-birth:last option:not([value="all"])').remove();
                                    el.find('.js-select-year-of-birth:last').append(selectOptions);
                                }
                            } else {
                                el.find('.js-select-year-of-birth:last option:not([value="all"])').remove();
                            }


                            el.find('select[name="updated_year_of_birth[]"]:last').empty();
                            el.find('select[name="updated_year_of_birth[]"]:last').append(selectOptionsTo);

                            if ( el.find(`td select[name="updated_year_of_birth[]"]:last option[value="${currentUpdatedAge}"]`).length !== 0 ) {
                                el.find('td select[name="updated_year_of_birth[]"]:last').val(currentUpdatedAge);
                            } else {
                                el.find('td select[name="updated_year_of_birth[]"]:last option:eq(1)').attr('selected', 'selected');
                            }
                        }


                        femaleStatusBlockHandlerInTable(cattleTypeGender, el);

                    });
            }


        } else {

            $(el).find('td[data-cattle-type-from] select option').not('[value="all"]').each(async function (i) {

                await axios.get('/property-cattle-type?cattle_type_id=' + $(this).val() + '&property_id=' + propertyId)
                    .then(cattleTypeData => {

                        let arrMaxAges = [];

                        let cattleTypeMinYearOfBirth = cattleTypeData.data.data.min_year_of_birth;

                        arrMaxAges.push(cattleTypeMinYearOfBirth);

                        let currentYear = new Date().getFullYear();

                        let selectOptions = '';
                        for (let i = currentYear; i >= cattleTypeMinYearOfBirth; i--) {
                            selectOptions += `<option value="${i}">${i}</option>`;
                        }

                        el.find('select[name="year_of_birth[]"]').append(selectOptions)
                        //el.find('select[name="updated_year_of_birth[]"]').append(selectOptions)
                    })

            });

        }
    }

    return 1;
}

export function insertPaddocks(items, extraQuantity, actionIndex) {

    let htmlPaddocks = '';

    if (items.length === 0) {
        htmlPaddocks = 'Related paddocks were not found';
    } else {

        for (const paddock in items) {
            let element = Object.values(items[paddock])

            htmlPaddocks += `
                <li class="list-group-item list-group-item d-flex justify-content-between align-items-center">
                    <input type="checkbox" class="js-extra-paddock" value="${element[0]}"/>
                    <i class="text-primary">Paddock: </i><span>${element[1]}</span>
                    <i class="text-primary">#: </i><span>${element[2]}</span>
                </li>
            `;
        }

    }
    $('#paddocks-selector ul').append(htmlPaddocks)
    $('#paddocks-selector .modal-footer').prepend(`<b>Required quantity: <span data-origin="${extraQuantity}">${extraQuantity}</span> </b>`)
    $('#paddocks-selector').data('action-key', actionIndex)

}

$('#paddocks-selector').on('change', '.js-extra-paddock', (e) => {

    let originQuantity = parseInt($('#paddocks-selector .modal-footer span').data('origin'))

    $('.js-extra-paddock').each(function() {
        if ( this.checked ) {
            originQuantity -= parseInt($(this).nextAll('i:last').next('span').text())
        }
    })

    if ( originQuantity <= 0 ) {
        $('#paddocks-selector .modal-footer span').text(0)
        $('#paddocks-selector button:last').removeAttr('disabled')
    } else {
        $('#paddocks-selector .modal-footer span').text(originQuantity)
        $('#paddocks-selector button:last').attr('disabled', 'disabled')
    }
})

$('#paddocks-selector button:last').on('click', function(e) {

    const actionRowNumber = $('#paddocks-selector').data('action-key')
    let paddockIDsRow = ''

    $(this).closest('#paddocks-selector').find('.js-extra-paddock').each(function() {
        if ( this.checked ) {
            let paddockId = this.value

            paddockIDsRow += `${paddockId},`
        }
    })

    $(`.action-in-table[data-index="${actionRowNumber}"]:first`).append(`<input type="hidden" form="actionsFormInTable" name="extra_paddocks[]" value="${paddockIDsRow}">`)
    $('.js-actions-form-in-table').trigger('submit');
})


/**
 * @param  {HTMLElement} elAction
 * @param  {number} actualQuantity
 */
export async function handleValidation(elAction, actualQuantity) {

    let elNeedToMoveQuantity = elAction.find('input[name="quantity[]"]:not([type="hidden"]):not([disabled])'),
        elMS = elAction.find('input[name="ms_number"]:not([disabled])'),
        elSma = null,
        elNote = elAction.find('input[name="note[]"]:not([disabled])'),
        elSMANote = elAction.find('input[name="sma_note[]"]:not([disabled])'),
        elNvd = elAction.find('input[name="nvd[]"]:not([type="hidden"]):not([disabled])'),
        elDecks = elAction.find('input[name="decks[]"]:not([type="hidden"]):not([disabled])'),
        cattleType = elAction.find('select.js-action-select-cattle-type:first:not([disabled])'),
        yearOfBirth = elAction.find('select[name="year_of_birth[]"]').val(),
        propertyTo = elAction.find('select[name="updated_property_id[]"]:not([type="hidden"]):not([disabled])'),
        isUpdate = elAction.find('input[name="transaction_id"]').length === 1,
        chosenAction = '';

    const transactionId = elAction.find('input[name="transaction_id"]').val()

    if (elAction.hasClass('action-in-table')) {
        chosenAction = elAction.find('select[name="action[]"]').val();
        elSma = elAction.find('input[name="sma[]"]:not([disabled])')
    }

    let request = '';
    const promises = [];

    clearValidation(elAction);

    if (elAction.hasClass('action-in-table')) {

        if (
            elNeedToMoveQuantity.val() !== ''
            && elAction.find('[data-animals-ids]').length > 0
            && !elAction.find('[data-animals-ids]').first().hasClass('js-stub')
        ) {
            setInvalid(elNeedToMoveQuantity, 'You can’t use the Quantity field and the Animal-related multi-select at once');

            modalActionsValidated = false;
        } else if (elNeedToMoveQuantity.val() === '' && elAction.find('[data-animals-ids]').length === 0 && yearOfBirth !== 'all') {
            setInvalid(elNeedToMoveQuantity, 'To make an action you need to choose either animals or quantity');
            modalActionsValidated = false;
        } else if (elNeedToMoveQuantity.length !== 0 && elNeedToMoveQuantity.val().length > 4) {
            setInvalid(elNeedToMoveQuantity, 'The quantity field must not be greater than 4 characters');
            modalActionsValidated = false;
        }
    }


    if ( cattleType.length !== 0 && (cattleType.val() === '0' || cattleType.val().length === 0) ) {
        setInvalid(cattleType, 'Select cattle type')
        modalActionsValidated = false
    }

    if (elMS.length !== 0 && !elMS.is(':disabled')) {

        if (elMS.val().length > 6) {
            setInvalid(elMS, 'The MS# must not be greater than 6 characters');
            modalActionsValidated = false;
        } else {
            elMS.next('.js-small-text').remove();
            elMS.removeClass('is-invalid');
        }


    }

    let smaValidationResult = await smaValidation(chosenAction, elSma, transactionId)
    promises.push(smaValidationResult);


    if ( $('.js-report-table').data('is-full-level') !== true
        && $('.js-ledger-table').data('extra-animals') == true
        && chosenAction !== "Transfer In"
        && chosenAction !== "Transfer Out"
        && parseInt(elNeedToMoveQuantity.val()) <= parseInt(actualQuantity)
    ) {
        if ( chosenAction === 'Brand' || chosenAction === 'Bought' ) {
            let cattleTypeId = $(elNeedToMoveQuantity).closest('tbody').find('.js-action-select-cattle-type').val();
            let paddockId = elAction.hasClass('action-in-table')
                ? elAction.find('input[type="hidden"][name="current_paddock_id"]').val()
                : $('input[name="current_paddock_id"]').val();
            let yearOfBirth = elAction.find('.js-select-year-of-birth').val();

            await axios.get(`/check-for-extra-animals?paddockId=${paddockId}&cattleTypeId=${cattleTypeId}&yearOfBirth=${yearOfBirth}`)
                .then(async (response) => {
                    if (response.data.result) {
                        await alertOnNegateExtraAnimals(elAction)
                        modalActionsValidated = false;
                    }


                })

        } else if ( chosenAction === "Reclass" || chosenAction === "Weaned" || chosenAction === "Process Weaners" || chosenAction === "Change Age" || chosenAction === "Moved" ) {
            let cattleTypeId = $(elAction).find('select[name="updated_cattle_type_id[]"]:not([disabled])').val() ?? $(elAction).find('select.js-action-select-cattle-type:not([disabled])').val();
            let paddockId = $(elAction).find('select[name="updated_paddock_id[]"]:not([disabled])').val() ?? $('input[name="current_paddock_id"]').val();
            let yearOfBirth = $(elAction).find('select[name="updated_year_of_birth[]"]:not([disabled])').val() ?? $(elAction).find('select[name="year_of_birth[]"]:not([disabled])').val()

            await axios.get(`/check-for-extra-animals?paddockId=${paddockId}&cattleTypeId=${cattleTypeId}&yearOfBirth=${yearOfBirth}`)
                .then(async (response) => {
                    if (response.data.result) {
                        await alertOnNegateExtraAnimals(elAction)
                        modalActionsValidated = false;
                    }

                })
        }



    }


    if (chosenAction === 'Bought' || chosenAction === 'Brand') {
        let resultQuantity = parseInt(actualQuantity) + parseInt(elNeedToMoveQuantity.val());

        if (resultQuantity > 2147483647 || resultQuantity < 0) {
            setInvalid(elNeedToMoveQuantity, 'Incorrect value');
            modalActionsValidated = false;
        } else if (
            $('.js-report-table').data('is-full-level') === true
            && parseInt(elNeedToMoveQuantity.val()) > parseInt(actualQuantity)) {

            setInvalid(elNeedToMoveQuantity, 'There are not enough animals');
            modalActionsValidated = false;
        }

    }
    else if (parseInt(elNeedToMoveQuantity.val()) > parseInt(actualQuantity)) {
        if( (!isUpdate || (chosenAction === "Transfer Out" && $(propertyTo).val().includes('|') ) )
            && chosenAction !== "Mortality"
            && chosenAction !== "Transfer In"
            && (chosenAction !== "Sold" ||  (chosenAction === "Sold" && $(propertyTo).val().includes('|') ))
        )
        {
            setInvalid(elNeedToMoveQuantity, 'There are not enough current-aged animals');
            modalActionsValidated = false
        }


        if (
            $('.js-report-table').data('is-full-level') === true
            && !isUpdate
            && chosenAction !== "Bought"
            && chosenAction !== "Brand"
            && chosenAction !== "Reclass"
            && chosenAction !== "Weaned"
            && chosenAction !== "Process Weaners"
            && chosenAction !== "Transfer In"
            && $('.action-in-table').not('.d-none').length === 1
            && confirm('Not enough of this cattle type. Would you like to reclass one?')
        ) {
            let cattleTypeId = $(elNeedToMoveQuantity).closest('tbody').find('.js-action-select-cattle-type').val();
            let paddockId = elAction.hasClass('action-in-table')
                ? elAction.find('input[type="hidden"][name="current_paddock_id"]').val()
                : $('input[name="current_paddock_id"]').val();
            let yearOfBirth = elAction.find('.js-select-year-of-birth').val();

            await forceReclass(elAction, paddockId, cattleTypeId, yearOfBirth);
        } else if ( $('.js-report-table').data('is-full-level') !== true
            && $('.js-ledger-table').data('extra-animals') == true
            && actualQuantity !== 0
            && parseInt(elNeedToMoveQuantity.val()) > parseInt(actualQuantity)
            && !isUpdate
        ) {
            let cattleTypeId = $(elNeedToMoveQuantity).closest('tbody').find('.js-action-select-cattle-type').val();
            let paddockId = elAction.hasClass('action-in-table')
                ? elAction.find('input[type="hidden"][name="current_paddock_id"]').val()
                : $('input[name="current_paddock_id"]').val();
            let yearOfBirth = elAction.find('.js-select-year-of-birth').val();

            if ( chosenAction === "Reclass" || chosenAction === "Weaned" || chosenAction === "Process Weaners" || chosenAction === "Change Age" || chosenAction === "Moved" ) {
                await extraAnimalsAlert(elAction, paddockId, cattleTypeId, yearOfBirth, parseInt(elNeedToMoveQuantity.val()) - parseInt(actualQuantity) )
            }

        }
    }


    if(chosenAction === "Bought") {
        const purchasedFrom = elAction.find('input[name="purchased_from[]"]:not([disabled])'),
            purchasedTo = elAction.find('input[name="purchased_to[]"]:not([disabled])');

        if (purchasedFrom.val() === '') {
            setInvalid(purchasedFrom, 'Purchased From field is required');
            modalActionsValidated = false;
        }


    }

    if ( chosenAction === "Bought"
        || chosenAction === "Sold"
        || chosenAction === "Transfer In"
        || chosenAction === "Transfer Out"
    ) {
        if (elNvd.val() === '') {
            setInvalid(elNvd);
            modalActionsValidated = false;
        }
        else if (elNvd.val().length > 15) {
            setInvalid(elNvd, 'The NVD must not be greater than 15 characters');
            modalActionsValidated = false;
        }

        if (elDecks.val() === '') {
            setInvalid(elDecks);
            modalActionsValidated = false;
        }
        else if (elDecks.val().length > 5) {
            setInvalid(elDecks, 'The decks must not be greater than 5 characters');
            modalActionsValidated = false;
        }

        if ( elSMANote.val().length !== 0 && elSMANote.val().length > 100) {
            setInvalid(elSMANote, 'The Notes must not be greater than 100 characters');
            modalActionsValidated = false;
        }



    }


    if (elNote.val().length > 50) {
        setInvalid(elNote, 'The note must not be greater than 50 characters');
        modalActionsValidated = false;
    }

    return promises;
}

/**
 * @param  {HTMLDivElement} elAction
 */
export function clearValidation(elAction) {
    elAction.find('.js-small-text').remove();
    elAction.find('.is-invalid').removeClass('is-invalid');
}

/**
 * @param  {HTMLElement} el
 * @param  {string} message
 */
export function setInvalid(el, message = 'This field is required') {
    if (!el.next().hasClass('js-small-text')) {
        el.after(`<span class="js-small-text">${message}</span>`);
        el.addClass('is-invalid');
    }
}

/**
 * @param  {HTMLElement} elAction
 * @param  {string|number} cattleTypeId
 * @param  {string|number} paddockId
 * @param  {string|number} yearOfBirth
 */
export async function forceReclass(elAction, paddockId, cattleTypeId, yearOfBirth) {

    const elSelect = $('.js-report-table .js-action-select-in-table');
    elSelect.val('Reclass').trigger('change');

    elAction.find('select[name="updated_cattle_type_id[]"]').val(cattleTypeId).trigger('change')


    setTimeout(() => {

        elAction.find('select[name="updated_year_of_birth[]"]:first').val(yearOfBirth);

    }, 2000);

}



$('body').on('input', '#msNumber', function(){
    this.value = this.value.replace(/[^0-9Aa-zA-Z]/gi, '');
});


