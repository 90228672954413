const {round} = require("@popperjs/core/lib/utils/math");
const axios = require("axios");
if ( window.location.pathname.includes('cattle-forecasts') ) {
    $(window).on('load', function () {
        $('form select[name="action"]').trigger('change')
        $('form select.js-forecast-value-switch').trigger('change')
    })
}

$('#forecast-table').on('click', 'td.js-action-cell span', function (e) {
    if ( $('#forecast-table input').length !== 0 ) return

    const cell = e.target
    const currentValue = $(cell).text()

    $(cell).closest('td').find('span').addClass('d-none')
    $(cell).closest('td').append(`<input type="number" class="form-control d-inline" style="width: 70px" min="1" value="${currentValue}">` +
                                    `<a><i class="fas fa-check-circle fs-4 font text-success align-middle js-recalculate-forecast mx-1"></i></a>`)
})

$('#forecast-table').on('click', '.js-recalculate-forecast', function (e) {
    const table = $(this).closest('table')

    const currentYear = $(this).closest('td').data('year')
    const input = $(this).closest('td').find('input')
    const action = $(this).closest('tr').find('td.js-action-name-cell-in-table span').text()

    const oldValue = parseInt($(this).closest('td').find('span').text())
    const newValue = $(input).val()

    const cattleType = $(this).closest('tr').data('cattle-type')
    const price = $(this).closest('tr').data('price')
    const currentActionRow = $(this).closest('tr');
    const currentCattleTypeRow = $(table).find(`tr[data-cattle-type="${cattleType}"]`)

    $(this).closest('td').find('span').removeClass('d-none').text(newValue)

    $(input).next('a').remove()
    $(input).remove()

    let currentYearOpening = parseInt($(currentCattleTypeRow).find(`.js-opening-cell[data-year="${currentYear}"]`).text())

    recalculateYear(currentCattleTypeRow, currentYear, currentYearOpening, true)


    if ( $('#forecast-values-table').length !== 0 ) {
        recalculateValues(currentActionRow, cattleType, action, price)
    }
})

function recalculateYear(currentCattleTypeRow, year, prevClosing, isFirstYear)
{
    const openingCell = $(currentCattleTypeRow).find(`.js-opening-cell[data-year="${year}"]`)

    $(openingCell).text(prevClosing)
    let currentYearOpening = parseInt($(openingCell).text())
    let currentYearClosing = currentYearOpening

    $(currentCattleTypeRow).find(`.js-action-cell[data-year="${year}"]`).each(function (i) {
        const action = $(this).closest('tr').find('td.js-action-name-cell-in-table span').text()
        let actionNumber = parseInt($(this).text())

        if ( !isFirstYear ) {
            const actionNumberType = $(this).closest('tr').data('number-type')
            const expectationNumber = $(this).closest('tr').data('expectation-number')


            let newActionNumber = actionNumberType === 'number' ? parseInt(expectationNumber) : currentYearOpening * (expectationNumber / 100)
            $(this).find('span').text(round(newActionNumber))

            actionNumber = round(newActionNumber)
        }

        switch (action) {
            case 'Brand':
            case 'Bought':
            case 'Transfer In':
                currentYearClosing += actionNumber
                break
            default:
                currentYearClosing -= actionNumber
                break
        }

    })


    $(currentCattleTypeRow).find(`.js-closing-cell[data-year="${year}"]`).text(currentYearClosing)


    if ( $(currentCattleTypeRow).find(`.js-opening-cell[data-year="${year + 1}"]`).length !== 0 ) {
        recalculateYear(currentCattleTypeRow,year + 1, currentYearClosing)
    }

}

function recalculateValues(actionRow, cattleType, action, price)
{
    const valuesTable = $('#forecast-values-table')
    const newForecast = $(actionRow).find(`td[data-year]`)

    $(newForecast).each(function () {
        const newForecast = parseInt($(this).find('span').text())

        const year = $(this).closest('td').data('year')
        const currentValueCell = $(valuesTable)
            .find(`tr[data-cattle-type="${cattleType}"] td[data-year="${year}"][data-action="${action}"] span`)

        const currentValue = parseInt($(currentValueCell).text())
        const newValue = newForecast * price

        const difference = newValue - currentValue

        $(currentValueCell).text(newValue)


        const totalCell = $(valuesTable)
            .find(`tr[data-cattle-type="total"] td[data-year="${year}"][data-action="${action}"] span`)

        const totalCellValue = parseInt($(totalCell).text())

        $(totalCell).text(totalCellValue + difference)

        const totalCashCell = $(valuesTable).find(`.js-total-cash-row td[data-year="${year}"] span`)
        if ( $(totalCashCell).length !== 0 && (action === 'Bought' || action === 'Sold')  ) {
            const currentTotalCash = parseInt($(totalCashCell).text())
            if ( action === 'Sold' ) {
                $(totalCashCell).text(currentTotalCash + difference)
            } else {
                $(totalCashCell).text(currentTotalCash - difference)
            }
        }
    })


}


$(document).on('change', '.js-forecast-action', function (e) {
    const instanceBlock = $(this).closest('form').find('.js-expectation-fields')
    const expectationsBlock = $(instanceBlock).find('.js-expectations-table')
    const singleRow = $(expectationsBlock).find(`tr[data-type="single"]`)
    const allActionsRows = $(expectationsBlock).find(`tr[data-type="all"]`)

    $(expectationsBlock).removeClass('d-none')

    if ( $(this).val() === 'all' ) {
        $(singleRow).addClass('d-none').find('input, select').attr('disabled', 'disabled')
        $(allActionsRows).removeClass('d-none').find('input:not(#price), select').removeAttr('disabled')

        $('.js-forecast-value-switch-block').removeClass('d-none')
            .find('.js-forecast-value-switch').trigger('change')

        $('#forecast-instance-controls').removeClass('d-none')
    } else if ( $(this).val() !== '' ) {
        $(singleRow).find('td.js-action-name-cell').text($(this).find('option:selected').text())

        $(allActionsRows).addClass('d-none').find('input, select').attr('disabled', 'disabled')
        $(singleRow).removeClass('d-none').find('input:not(#price), select').removeAttr('disabled')

        $('.js-forecast-value-switch-block').removeClass('d-none')
            .find('.js-forecast-value-switch').trigger('change')

        $('#forecast-instance-controls').removeClass('d-none')
    } else {
        $(expectationsBlock).addClass('d-none').find('input, select').attr('disabled', 'disabled')

        $('.js-forecast-value-switch-block').addClass('d-none')

        if ( $('.js-forecast-value-switch').prop('checked') ) {
            $('.js-forecast-value-switch').prop('checked', false).trigger('change')
        }

        $('#forecast-instance-controls').addClass('d-none')

    }
})

$(document).on('change', '.js-forecast-properties', function(e) {
    let target = e.target
    console.trace(target)
    let properties = $(target).val()
    let propertiesSelector = $(this)

    axios.get(`/get-properties-data?properties=${properties}&queryType=ajax`)
        .then(response => {

            const paddocksSelector = $(propertiesSelector).closest('tbody').find('select.js-forecast-paddocks:not([disabled])')

            $(paddocksSelector).selectpicker('deselectAll');
            $(paddocksSelector).empty();
            $(paddocksSelector).selectpicker('destroy')

            $(paddocksSelector).selectpicker();
            $(paddocksSelector).selectpicker('render')

            response.data.paddocks.forEach(function (value, index) {
                $(paddocksSelector).append(`<option value="${value.id}">${value.name} (${value.property.name})</option>`)
            })

            $(paddocksSelector).trigger('change')
            $(paddocksSelector).selectpicker('refresh')

        })

});

$('.js-compress-cattle-type-forecast').on('click', function (e) {
    const cattleTypeName = $(this).closest('td').data('cattle-type')
    const table = $(this).closest('table')

    $(this).addClass('d-none').next('a').removeClass('d-none')
    $(table).find(`tr[data-cattle-type="${cattleTypeName}"]`).addClass('d-none')
})

$('.js-expand-cattle-type-forecast').on('click', function (e) {
    const cattleTypeName = $(this).closest('td').data('cattle-type')

    $(this).addClass('d-none').prev('a').removeClass('d-none')

    $('#forecast-table tbody, #forecast-values-table tbody').find(`tr[data-cattle-type="${cattleTypeName}"]`).removeClass('d-none')
})

$('.js-add-forecast-instance').on('click', function (e) {
    const lastInstanceContainer = $('.js-expectation-fields:last');
    const instanceHTML = $(lastInstanceContainer)[0].outerHTML
    const newIndex = parseInt($(lastInstanceContainer).data('index')) + 1

    $(lastInstanceContainer).after(instanceHTML)

    const newLastInstanceContainer = $('.js-expectation-fields:last');

    $(newLastInstanceContainer).data('index', newIndex)
    $(newLastInstanceContainer).find('.js-forecast-properties').attr('name', `properties[${newIndex}][]`)
    $(newLastInstanceContainer).find('.js-forecast-paddocks').attr('name', `paddocks[${newIndex}][]`)
    $(newLastInstanceContainer).find('.js-forecast-number').attr('name', `number[${newIndex}][]`)
    $(newLastInstanceContainer).find('.js-forecast-number-type').attr('name', `number_type[${newIndex}][]`)
    $(newLastInstanceContainer).find('.js-forecast-value').attr('name', `price[${newIndex}][]`)

})

$('.js-remove-forecast-instance').on('click', function (e) {
    if ( $('.js-expectation-fields').length !== 1 ) {
        $('.js-expectation-fields:last').remove()
    }
})

$(document).on('change', '.js-forecast-value-switch', function (e) {

    if ( this.checked ) {
        $('.js-expectation-fields')
            .find('tbody tr:not(.d-none) .js-forecast-value').removeAttr('disabled')
        $('.js-expectation-fields')
            .find('tbody tr.d-none .js-forecast-value').attr('disabled', 'disabled')
    } else {
        $('.js-expectation-fields').find('.js-forecast-value').attr('disabled', 'disabled')
    }

})
