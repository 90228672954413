$('.js-report-table').on('change', 'select[name="year_of_birth[]"]', async function (e) {
    let select = e.target;

    if ( $(select).attr('disabled') ) {
        return
    }

    let selectAction = $(select).closest('tr');

    if ( $(select).val() === 'all' ) {

        let quantitySum = 0;
        let cattleType = $(selectAction).find('.js-action-select-cattle-type:first').val();

        if ( $(select).closest('tr').find('td[data-action] select').val() === 'Transfer In' ) {
            let propertyId = $(select).closest('tr').find('select[name="updated_property_id[]"]').val();

            const recievePaddockCattlesQuantity = await axios.get(`/get-paddock-cattles-quantity?property=${propertyId}&cattle_types=${cattleType}`)

            quantitySum = recievePaddockCattlesQuantity.data.quantity;
        } else {
            $(`.js-ledger-table tbody tr td[data-current-cattle-type-id="${cattleType}"][draggable="true"]`).each(function (i) {
                quantitySum += parseInt($(this).find('a').text());
            });

        }

        $(selectAction).find('input[name="quantity[]"]').val(quantitySum);
        $(selectAction).find('input[name="quantity[]"]').attr('readonly', '');
    } else if ( $(select).val().includes('+') ) {
        let cattleType = $(selectAction).find('.js-action-select-cattle-type:first').val();
        let ageBorderQuantity = $(`.js-ledger-table tbody tr td.age-border-total[data-current-cattle-type-id="${cattleType}"]`).text().trim()

        $(selectAction).find('input[name="quantity[]"]').val(ageBorderQuantity);
        $(selectAction).find('input[name="quantity[]"]').attr('readonly', '');
    } else {
        $(selectAction).find('input[name="quantity[]"]').removeAttr('readonly');
    }

});
