import {blockActionsAlert} from "../actions-alerts";
import {datepickerInit} from "../components-settings";
import {propertyConfirmed} from "../cattle-type/table-actions-cattle-type";
import {initTransactionData} from "./init-transaction-data";
import {initCreateActionsTable} from "./init-transaction-rows";

const itemAddedEvent = new Event('transaction-changed');

$('.js-add-transaction-first').on('click', (e) => {

    if ( !propertyConfirmed() ) {
        blockActionsAlert()
        return
    }

    initCreateActionsTable();

    $('.js-add-action-instance-in-table').trigger('click');
})

$('.js-add-action-instance-in-table, .js-add-line-action-to-table').on('click', function (e) {
    e.preventDefault();

    if ( !propertyConfirmed() ) {
        blockActionsAlert()
        return
    }

    if ( $('.js-add-action-instance-in-table').attr('disabled') ) return;

    const transactionsTable = $('.js-report-table')
    const actionRowHTML = $('.js-add-transactions-table tbody').html();
    const actions = $(transactionsTable).find('.action-in-table');


    if ( actions.length === 0 ) {
        $(transactionsTable).find('tbody').prepend(actionRowHTML)
    } else {
        $(actions).last().after(actionRowHTML)
    }

    addIndex();

    const elInstanceLast = $(transactionsTable).find('.action-in-table:last');
    const elInstanceLastIndex = $(elInstanceLast).data('index')

    fillPropertyData(elInstanceLast)

    $(elInstanceLast).find('td[data-cattle-type-from] select').val($(this).data('cattle-type-id'));
    $(elInstanceLast).find('select[name="year_of_birth[]"]').append('<option value="' + $(this).data('age') + '">' + $(this).data('age') + '</option>')
    $(elInstanceLast).find('select[name="year_of_birth[]"]').val($(this).data('age')).change();
    $(elInstanceLast).find('input[name="quantity[]"]').val($(this).text());

    $(elInstanceLast).find('td[data-cattle-type-from] select').attr('name', `current_cattle_type_id[${elInstanceLastIndex}][]`);
    $(elInstanceLast).find('td[data-treatment] select').attr('name', `treatment[${elInstanceLastIndex}][]`);
    if ( elInstanceLastIndex !== 0 ) {
        $(elInstanceLast).find('td[data-date] input').remove();
    }

    if ( $(this).hasClass('js-add-line-action-to-table') ) {
        initCreateActionsTable();

        $(elInstanceLast).addClass('pre-filled');
        $('html, body').animate({
            scrollTop: $(".js-report-table").parent().offset().top
        }, 100);
    } else {
        $(elInstanceLast).removeClass('pre-filled');
    }


    $('.js-update-action-button, .js-delete-transaction, .js-copy-transaction').addClass('d-none')

    $(elInstanceLast).removeClass('d-none');
    document.dispatchEvent(itemAddedEvent);

    datepickerInit();

});

if (window.location.pathname.includes('paddock-select')) {
    let params = (new URL(document.location)).searchParams;

    if ( params.get('age') !== null && params.get('resend') === null ) {
        let yearOfBirth = params.get('age');
        let cattleTypeId = params.get('cattleTypeId');
        let quantity = params.get('quantity');
        let group = params.get('group_id') ?? null;

        $(window).on('load', function (e) {
            e.preventDefault();

            $('.js-add-transaction-first').trigger('click');

            yearOfBirth = yearOfBirth !== undefined && params.get('isBorder') !== null ? yearOfBirth.trim() + '+' : yearOfBirth

            const elAction = $('.js-report-table .action-in-table');
            $(elAction).addClass('pre-filled');

            elAction.first().removeClass('d-none');

            elAction.find('select[name="current_cattle_type_id[0][]"]').val(cattleTypeId);


            elAction.find('select[name="year_of_birth[]"]').append('<option value="' + yearOfBirth + '">' + yearOfBirth + '</option>')
            elAction.find('select[name="year_of_birth[]"]').val(yearOfBirth).change();

            elAction.find('input[name="quantity[]"]').val(quantity);


            $(`.js-ledger-table thead tr:first th[data-group-id="${group}"]:first`).find('.js-expand-cattle-types').trigger('click');

            elAction.find('select[name="updated_cattle_type_id[]"]').val('0');
            elAction.find('select[name="updated_paddock_id[]"]').val('0')


            addIndex();

            $('html, body').animate({
                scrollTop: $(".js-report-table").parent().offset().top
            }, 100);


        });
    } else if ( params.get('resend') !== null ) {
        $(window).on('load', function () {
            $('.js-add-transaction-first').trigger('click');
            let elAction = $('.js-report-table tbody .action-in-table:first');
            const action = params.get('action');

            $(elAction).addClass('pre-filled');

            elAction.find('.js-action-select-in-table').val(action).trigger('change');



            if ( action === 'Sold' ) {
                elAction.find('.js-property-block-for-sale select').val(params.get('property')).trigger('change');
            } else {
                elAction.find('.js-property-block-for-transfer-out select').val(params.get('property')).trigger('change');
            }

            setTimeout(function () {
                elAction.find('.js-action-select-cattle-type:first').val(params.get('cattle_type')).trigger('change');
            }, 500);


            setTimeout(function () {
                elAction.find('select[name="year_of_birth[]"]').val(params.get('age')).trigger('change');

                if ( params.get('mob') !== '0' ) {
                    elAction.find('td[data-mob] input:last').val(params.get('mob'));
                } else {
                    elAction.find('td[data-quantity] input:first').val(params.get('quantity')).trigger('input');
                }


            }, 1000);

            $('html, body').animate({
                scrollTop: $(".js-report-table").parent().offset().top
            }, 100);



        });
    } else if ( params.get('transactionId') !== null ) {

        $(window).on('load', function () {
            window.history.pushState({}, '', window.location.pathname);
            initTransactionData(params.get('transactionId'));
        });


    }

}

function addIndex() {
    const transactionsRows = $('.js-report-table .action-in-table')
    const indexOfEl = $(transactionsRows).length - 1;

    $(transactionsRows).last().attr('data-index', indexOfEl);
}

function fillPropertyData(elInstanceLast)
{

}
