import {initialAutocomplete} from "./cattle/autocomplete";
import {round} from "@popperjs/core/lib/utils/math";
import axios from "axios";

$('.js-costs-cattle').each(async function(i, el) {
    let cattleData = await axios.get(`/get-cattle-data?search=yes&forCosts=true`);
    initialAutocomplete(el, cattleData.data.cattle);
})

$('.js-costs-lot-number').each(async function(i, el) {
    let lotNumberData = await axios.get(`/get-lot-number`);

    initialAutocomplete(el, lotNumberData.data.lotNumbers);
})


$('.js-costs-type').on('change', async function (e) {
    const type = $(this).val()
    const typesContainer = $('.js-costs-types-container')

    $(typesContainer).find('div.js-cost-type-container').addClass('d-none')
    $(typesContainer).find(`div.js-${type}-type`).removeClass('d-none')

    if (type === 'company') {
        const count = await getAnimalsCount()

        $('.js-company-type .js-costs-heads').text(count)
    }

})

$('.js-add-cost').on('click', function (e) {
    const currentContainer = $('.js-cost-type-container:not(.d-none)')
    const table = $(currentContainer).find('.js-additional-costs-table')
    const row = $(table).find('tbody tr:first-child')[0].outerHTML

    $(table).find('tbody').append(row)
    $(table).find('tbody tr:last-child input').val('')

    $('.js-remove-specific-cost').removeClass('d-none')
})

$('.js-remove-cost').on('click', function (e) {
    const currentContainer = $('.js-cost-type-container:not(.d-none)')
    const table = $(currentContainer).find('.js-additional-costs-table')
    const rows = $(table).find('tbody tr')

    if ( $(rows).length !== 1 ) {
        $(rows).last().remove()
        $(currentContainer).find('.js-cost-value:first-child').trigger('change')
    }

    if ( $(table).find('tbody tr').length === 1 ) {
        $('.js-remove-specific-cost').addClass('d-none')
    }

})

$('body').on('click', '.js-remove-specific-cost', function (e) {
    const currentContainer = $('.js-cost-type-container:not(.d-none)')
    const table = $(currentContainer).find('.js-additional-costs-table')
    const rows = $(table).find('tbody tr')

    if ( $(rows).length !== 1 ) {
        $(this).closest('tr').remove()
        $(currentContainer).find('.js-cost-value:first-child').trigger('change')
    }

    if ( $(table).find('tbody tr').length === 1 ) {
        $('.js-remove-specific-cost').addClass('d-none')
    }
})

$('body').on('change', '.js-cost-value', function (e) {
    const currentContainer = $('.js-cost-type-container:not(.d-none)')
    const totalCostOfCarryContainer = $(currentContainer).find('.js-total-cost-of-carry')
    const totalCostOfCarryPerHeadContainer = $(currentContainer).find('.js-total-cost-of-carry-per-head')
    const heads = $(currentContainer).find('.js-costs-heads').text() ?? 0
    let total = 0;

    $(currentContainer).find('.js-cost-value').each(function (el) {
        const cost = $(this).val() === '' ? 0 : parseInt($(this).val())

        total += cost
    })

    $(totalCostOfCarryContainer).text(total)

    if ( parseInt(heads) !== 0 ) {
        $(totalCostOfCarryPerHeadContainer).text(round(total / parseInt(heads)))
    }

})

export async function getAnimalsCount(propertyId = '', paddockId = '', lotNumber = '', cattleType = '')
{
    return (await axios.get(`/get-animals-count?propertyId=${propertyId}&paddockId=${paddockId}&lotNumber=${lotNumber}`)).data.animalsCount
}

$('.js-costs-property-for-property-type').on('change', async function (e) {
    const currentContainer = $('.js-cost-type-container:not(.d-none)')
    const propertyId = $(this).val()

    const count = await getAnimalsCount(propertyId)
    $(currentContainer).find('.js-costs-heads').text(count)

    if ( count === 0 ) {

    }

    $(currentContainer).find('.js-cost-value:first-child').trigger('change')
})

$('.js-costs-property-for-paddock-type, .js-costs-property-for-animal-type').on('change', function (e) {
    const propertyId = $(this).val()
    updatePaddocksSelector(propertyId)
})

export function updatePaddocksSelector(propertyId, selectedPaddock = null)
{
    const currentContainer = $('.js-cost-type-container:not(.d-none)')

    axios.get(`/get-properties-data?properties=${propertyId}&queryType=ajax`)
        .then(response => {

            const paddocksSelector = $(currentContainer).find('.js-costs-paddock-for-animal-type, .js-costs-paddock-for-paddock-type')

            $(paddocksSelector).find('option:not(:first-child)').remove()
            response.data.paddocks.forEach(function (value, index) {
                $(paddocksSelector).append(`<option value="${value.id}">${value.name}</option>`)
            })
            $(paddocksSelector).trigger('change')

            if ( selectedPaddock !== null ) {
                $(paddocksSelector).val(selectedPaddock)
            } else {
                $(currentContainer).find('.js-search-input').val('')
            }

        })
}

$('.js-costs-property-for-animal-type, .js-costs-paddock-for-animal-type, .js-costs-cattle-type-for-animal-type').on('change', async function (e) {
    const currentContainer = $('.js-cost-type-container:not(.d-none)')

    const propertyId = $(currentContainer).find('.js-costs-property-for-animal-type').val() ?? '0'
    const paddockId = $(currentContainer).find('.js-costs-paddock-for-animal-type').val() ?? '0'
    const cattleTypeId = $(currentContainer).find('.js-costs-cattle-type-for-animal-type').val()
    const searchAnimalSelector = $(currentContainer).find('.js-costs-cattle')

    let newCattleData = await axios.get(`/get-cattle-data?search=yes&forCosts=true&propertyId=${propertyId}&paddockId=${paddockId}&cattleTypeId=${cattleTypeId}`);
    await initialAutocomplete(searchAnimalSelector[0], newCattleData.data.cattle);

})

$('.js-costs-property-for-paddock-type, .js-costs-paddock-for-paddock-type, .js-costs-lot-number').on('change', async function (e) {
    const currentContainer = $('.js-cost-type-container:not(.d-none)')

    const propertyId = $(currentContainer).find('.js-costs-property-for-paddock-type').val()
    const paddockId = $(currentContainer).find('.js-costs-paddock-for-paddock-type').val()
    let searchLotNumberSelector = $(currentContainer).find('.js-costs-lot-number')

    let newLotNumbersData = await axios.get(`/get-lot-number?propertyId=${propertyId}&paddockId=${paddockId}`);
    initialAutocomplete(searchLotNumberSelector[0], newLotNumbersData.data.lotNumbers);

    let newSearchLotNumberSelector = $(currentContainer).find('.js-costs-lot-number')
    console.log($(newSearchLotNumberSelector).val())
    let count = 0
    if ( $(newSearchLotNumberSelector).val() !== '') {
        count = await getAnimalsCount(propertyId, paddockId, $(newSearchLotNumberSelector).val())
    }

    $(currentContainer).find('.js-costs-heads').text(count)
})

$('.js-add-cost-of-carry-to-cattle').on('click', async function (e) {
    const currentType = $('.js-costs-type').val()
    const costOfCarry = $(this).closest('.js-cost-type-container').find('.js-total-cost-of-carry').text()

    if (costOfCarry === '0') {
        alert('Add cost of carry')
        return
    }

    switch (currentType) {
        case 'animal':
            const animalId = $('.js-costs-cattle').val()

            if (animalId === '') {
                alert('Add an animal ID')
                return;
            }

            let isThereCostOfCarryForAnimal = await axios.get(`/check-for-cost-of-carry?type=${currentType}&animalId=${animalId}`)
            if ( isThereCostOfCarryForAnimal.data === 0
                || isThereCostOfCarryForAnimal.data !== 0 && confirm('This animal\'s Cost of Carry has already been entered. Do you want to update it?')
            ) {
                await axios.get(`/update-cost-of-carry-for-animals?type=${currentType}&animalId=${animalId}&costOfCarry=${costOfCarry}`)

                alert('Updated')
            }


            break
        case 'paddock':
            const lotNumber = $('.js-costs-lot-number').val()

            if (lotNumber === '') {
                alert('Add a lot Number')
                return;
            }

            let isThereCostOfCarryForPaddock = await axios.get(`/check-for-cost-of-carry?type=${currentType}&lotNumber=${lotNumber}`)
            if ( isThereCostOfCarryForPaddock.data === 0
                || isThereCostOfCarryForPaddock.data !== 0 && confirm('This animal\'s Cost of Carry has already been entered. Do you want to update it?')
            ) {
                await axios.get(`/update-cost-of-carry-for-animals?type=${currentType}&lotNumber=${lotNumber}&costOfCarry=${costOfCarry}`)

                alert('Updated')
            }

            break
        case 'property':
            const propertyId = $('.js-costs-property-for-property-type').val()

            if (propertyId === '0') {
                alert('Choose a feedlot')
                return;
            }

            let isThereCostOfCarryForProperty = await axios.get(`/check-for-cost-of-carry?type=${currentType}&propertyId=${propertyId}`)
            if ( isThereCostOfCarryForProperty.data === 0
                || isThereCostOfCarryForProperty.data !== 0 && confirm('This animal\'s Cost of Carry has already been entered. Do you want to update it?')
            ) {
                await axios.get(`/update-cost-of-carry-for-animals?type=${currentType}&propertyId=${propertyId}&costOfCarry=${costOfCarry}`)

                alert('Updated')
            }

            break
        case 'company':

            let isThereCostOfCarryForCompany = await axios.get(`/check-for-cost-of-carry?type=${currentType}`)
            if ( isThereCostOfCarryForCompany.data === 0
                || isThereCostOfCarryForCompany.data !== 0 && confirm('This animal\'s Cost of Carry has already been entered. Do you want to update it?')
            ) {
                await axios.get(`/update-cost-of-carry-for-animals?type=${currentType}&costOfCarry=${costOfCarry}`)

                alert('Updated')
            }

            break
    }



})

$('.js-reset-costs-fields').on('click', function (e) {
    const currentContainer = $('.js-cost-type-container:not(.d-none)')

    $(currentContainer).find('input:not([type="button"])').val('').trigger('change')
    $(currentContainer).find('select').val('0').trigger('change')

    $(currentContainer).find('.js-remove-specific-cost:not(:first-child)').trigger('click')
})
