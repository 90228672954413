const {addAgesToSelect} = require("../cattle-return");
const {showFieldsByAction} = require("./change-action-in-modal");
$('.js-show-actions-modal').on('click', async function (e) {
    e.preventDefault()

    if ( $('.js-confirm-monthly-button').length !== 0 ) {
        alert('Need a confirm or update')
        return
    }

    const actionsModal = $('#cattleReturnActions')

    $(actionsModal).modal('show')

    const propertyId = window.location.pathname.split('/')[2]
    const cattleTypeId = $(this).data('cattle-type')
    const ageOfBirth = $(this).data('age')

    $(actionsModal).data('property-id', propertyId)
    $(actionsModal).data('cattle-type-id', cattleTypeId)
    $(actionsModal).data('age', ageOfBirth )

    let mortalityData = await axios.get(`/get-mortalities/${propertyId}?&cattleTypeId=${cattleTypeId}&year_of_birth=${ageOfBirth}`);
    const currentMortalities = $(this).data('mortalities')
    fillMortality(mortalityData, currentMortalities)

    $(actionsModal).find('.js-current-cattle-type').val(cattleTypeId)
    console.log($(actionsModal).find('.js-current-cattle-type').val())
    $(actionsModal).find('.js-current-year-of-birth').val(ageOfBirth)

    $('div[data-action="notMortality"]').data('total', mortalityData.data.data.currentQuantity)

});

function fillMortality(mortalityData, currentMortalities)
{
    const totalAnimals = mortalityData.data.data.currentQuantity + currentMortalities;
    let percentMortalities = Math.round(currentMortalities === 0 ? 0 : currentMortalities / totalAnimals * 100)

    $('#cattleReturnActions .container[data-action="mortality"] .row.mb-2:first-child .col-md-6 div span:first-child').text(`${currentMortalities}`)
    $('#cattleReturnActions .container[data-action="mortality"] .row.mb-2:first-child .col-md-6 div span:last-child').text(`(${percentMortalities}%)`)

    $('#cattleReturnActions .container[data-action="mortality"] .row.mb-2:eq(2) .col-md-6 div span:first-child').text(`${currentMortalities}`)
    $('#cattleReturnActions .container[data-action="mortality"] .row.mb-2:eq(2) .col-md-6 div span:last-child').text(`(${percentMortalities}%)`)

    $('#cattleReturnActions .container[data-action="mortality"] .row.mb-2:eq(1) .col-md-6 input:first').data('total', totalAnimals)

}
